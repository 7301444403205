import { Component, OnInit, inject } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { isNumber } from '@ui/legacy-lib';
import * as fromAppState from 'tenant-pool/+state';
import { MainPageNavigation } from 'tenant-pool/config';
import { TranslateModule } from '@ngx-translate/core';
import { NgStyle } from '@angular/common';
import { ComponentsModule } from '@ui/legacy-lib';
import { Go } from '@ui/legacy-lib';

@UntilDestroy()
@Component({
  selector: 'app-profile-completeness',
  templateUrl: './profile-completeness.component.html',
  styleUrls: ['./profile-completeness.component.scss'],
  standalone: true,
  imports: [ComponentsModule, NgStyle, TranslateModule]
})
export class ProfileCompletenessComponent implements OnInit {
  private store = inject(Store);

  public completeness: number;

  ngOnInit() {
    this.store
      .select(fromAppState.getProfileCompleteness)
      .pipe(untilDestroyed(this))
      .subscribe(completeness => (this.completeness = completeness));
  }

  get hasIncompleteProfile() {
    return isNumber(this.completeness) && this.completeness !== 100;
  }

  public editProfile() {
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }
}
