import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonTypeEnum } from '@ui/legacy-lib';
import { externalDataPrivacyLink } from 'tenant-pool/config';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-social-login-confirmation-modal',
  templateUrl: './social-login-confirmation-modal.component.html',
  styleUrls: ['./social-login-confirmation-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class SocialLoginConfirmationModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public type: ButtonTypeEnum = ButtonTypeEnum.LIGHT_BORDERED;
  public dataProtectionLink = externalDataPrivacyLink;

  public continue() {
    this.ngbActiveModal.close();
  }

  public close() {
    this.ngbActiveModal.dismiss();
  }
}
