import { Store } from '@ngrx/store';
import moment from 'moment';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import {
  ApplicantStatus,
  AppointmentsBundles,
  Attachment,
  Constructor,
  MarketingType,
  PropertyApplicationStatus,
  PropertyBean,
  PropertyMatchBean,
  PropertySearcherType
} from '@ui/shared/models';
import { ModalService } from '@ui/legacy-lib';

import {
  CheckForConversation,
  ClipboardService,
  DownloadService,
  getConstants,
  Go,
  LocalStorageService
} from '@ui/legacy-lib';
import { CheckforConversationInterface } from '@ui/legacy-lib';
import { ConfirmReasonModalComponent } from '@ui/legacy-lib';
import { sharedDialogConfig } from '@ui/legacy-lib';

import {
  dialogConfig,
  MainPageNavigation,
  storageKeys
} from 'tenant-pool/config';
import * as fromAppState from 'tenant-pool/+state';
import {
  getPropertyMatchesQuestions,
  OpenCompleteModal
} from 'tenant-pool/+state';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  isSalesObject
} from '@ui/legacy-lib';

export interface PropertiesActions {
  /* eslint-disable @typescript-eslint/ban-types */
  onApply: Function;
  onRemoveProperty: Function;
  onViewInvitation: Function;
  onViewAppointment: Function;
  onNoFittingAppointment: Function;
  onAnswerCustomQuestion: Function;
  onShareProperty: Function;
  onExportPDF: Function;
  onExportToCalendar: Function;
  onSetIntent: Function;
  onCancelAppointment: Function;
  onDownloadDocument: Function;
  onOpenChat: Function;
  openSelfDisclosureModal: Function;
  canChooseAnotherAppointment: Function;
  hasAppointmentInTheFuture: Function;
  hasCustomQuestion: Function;
  showExposeDownload: Function;
  hasExclusiveAppointments: Function;
  isIntent: Function;
  isNoIntent: Function;
  isFlat: Function;
  isGarage: Function;
  isCommercial: Function;
  isSales: Function;
  isShowSelfDisclosure: Function;
  isProposal: Function;
  loadPropertyMatchBeanQuestions: Function;
  /* eslint-enable @typescript-eslint/ban-types */
}

export type PropertiesActionsCtor = Constructor<PropertiesActions>;

export function mixinPropertiesActions<TBase extends Constructor>(
  Base: TBase
): PropertiesActionsCtor & TBase {
  return class extends Base {
    public propertyMatch: PropertyMatchBean;
    public appointmentsData: AppointmentsBundles[];
    public profileComplete$: Observable<boolean>;
    public profileCompleteness$: Observable<number>;

    private store: Store;
    private modalService: ModalService;
    private localStorageService: LocalStorageService;
    private clipboardService: ClipboardService;
    private downloadService: DownloadService;

    public isIntent(status: string) {
      return status === PropertyApplicationStatus.INTENT;
    }

    public isNoIntent(status: string) {
      return status === PropertyApplicationStatus.NO_INTENT;
    }

    public isFlat(property: PropertyBean) {
      return isPropertyTypeFlat(property?.type);
    }

    public isGarage(property: PropertyBean) {
      return isPropertyTypeGarage(property?.type);
    }

    public isCommercial(property: PropertyBean) {
      return isPropertyTypeCommercial(property?.type);
    }

    public isSales(marketingType: MarketingType) {
      return isSalesObject(marketingType);
    }

    public isShowSelfDisclosure(property: PropertyBean) {
      return !!property?.selfDisclosureId;
    }

    public isProposal(propertyMatch: PropertyMatchBean) {
      return propertyMatch?.type === PropertySearcherType.PROPOSAL;
    }

    public onApply(propertyMatch: PropertyMatchBean) {
      this.store.dispatch(
        OpenCompleteModal({ showGenericModal: false, propertyMatch })
      );
    }

    public onRemoveProperty(propertyMatch: PropertyMatchBean) {
      const modalDetails = this.isProposal(propertyMatch)
        ? { ...dialogConfig.property.removeProposal }
        : { ...dialogConfig.property.removeApplication };

      this.store
        .select(getConstants)
        .pipe(
          filter(constants => !!constants),
          map(constants => constants.refusalReasonTypes),
          take(1),
          switchMap(reasons =>
            this.modalService
              .open<ConfirmReasonModalComponent>(ConfirmReasonModalComponent, {
                data: {
                  ...modalDetails,
                  reasons
                }
              })
              .onClose()
              .pipe(take(1))
          )
        )
        .subscribe(reasonData => {
          const payload = {
            ...reasonData,
            propertySearcherId: propertyMatch?.id
          };
          return this.store.dispatch(
            this.isProposal(propertyMatch)
              ? fromAppState.DenyProposal({ reasonData: payload })
              : fromAppState.RemoveApplication({
                  reasonData: payload,
                  rented: propertyMatch?.status === ApplicantStatus.TENANT
                })
          );
        });
    }

    public editProfile() {
      this.store.dispatch(
        new Go({
          path: [MainPageNavigation.PROFILE, 'edit', 'step']
        })
      );
    }

    public onViewInvitation() {
      this.store.dispatch(
        new Go({
          path: [MainPageNavigation.PROPERTIES, MainPageNavigation.APPOINTMENTS]
        })
      );
    }

    public onViewAppointment() {
      this.store.dispatch(
        new Go({
          path: [MainPageNavigation.PROPERTIES, MainPageNavigation.APPOINTMENTS]
        })
      );
    }

    public onNoFittingAppointment() {
      this.store.dispatch(
        new Go({
          path: [MainPageNavigation.PROPERTIES, MainPageNavigation.APPOINTMENTS]
        })
      );
    }

    public onAnswerCustomQuestion(propertyMatch: PropertyMatchBean) {
      if (!propertyMatch?.property || !propertyMatch?.hasQuestions) return;

      this.localStorageService.setItem(
        storageKeys.customQuestionApplicationId,
        propertyMatch?.id
      );

      this.loadPropertyMatchBeanQuestions(propertyMatch);
    }

    public loadPropertyMatchBeanQuestions(propertyMatch: PropertyMatchBean) {
      this.store.dispatch(
        fromAppState.LoadPropertyMatchBeanQuestions({
          id: propertyMatch.id,
          status: propertyMatch.status
        })
      );

      this.store
        .select(fromAppState.getPropertyMatchQuestionsActionState)
        .pipe(
          filter(state => !state.pending && state.done),
          switchMap(() => this.store.select(getPropertyMatchesQuestions)),
          take(1)
        )
        .subscribe(questionContainer => {
          this.store.dispatch(
            new fromAppState.OpenCustomQuestionsModal({
              ...propertyMatch,
              questionContainer
            })
          );
        });
    }

    public onShareProperty(link: string) {
      this.clipboardService.copyToClipboard(link);
    }

    public onExportToCalendar(propertyMatch: PropertyMatchBean) {
      const {
        property,
        upcomingAppointmentId: id,
        upcomingAppointmentDate: date
      } = propertyMatch;

      const data = {
        property: {
          name: property.data.name
        },
        appointment: {
          id,
          date
        }
      };

      this.store.dispatch(new fromAppState.ExportCalendarFile(data));
    }

    public onExportPDF(applicationId: string) {
      this.downloadService
        .downloadPropertyExpose(applicationId, undefined, true)
        .pipe(take(1))
        .subscribe(exposeFile =>
          this.downloadService.download(
            exposeFile as any,
            `expose-${applicationId}.pdf`
          )
        );
    }

    public onSetIntent(
      data: { propertyMatch: PropertyMatchBean; intent: string },
      fromEmail = false
    ) {
      const { propertyMatch, intent } = data;
      this.store.dispatch(
        fromAppState.OpenIntentModal({
          application: propertyMatch,
          intent,
          fromEmail
        })
      );
    }

    public onCancelAppointment(appointmentId: string) {
      this.store
        .select(getConstants)
        .pipe(
          filter(constants => !!constants),
          map(constants => constants.appointmentAcceptanceCancelReasonTypes),
          switchMap(reasons =>
            this.modalService
              .open<ConfirmReasonModalComponent>(ConfirmReasonModalComponent, {
                data: {
                  ...sharedDialogConfig.appointment.cancelAppointment,
                  reasons
                }
              })
              .onClose()
              .pipe(take(1))
          )
        )
        .subscribe(data =>
          this.store.dispatch(
            new fromAppState.CancelAppointment({
              ...data,
              applicationId: this.propertyMatch?.id,
              appointmentId
            })
          )
        );
    }

    public onDownloadDocument(attachment: Attachment) {
      this.downloadService.downloadByUrl(attachment);
    }

    public onOpenChat(data: CheckforConversationInterface) {
      const { property, propertySearcher } = data;
      this.store.dispatch(
        new CheckForConversation({
          propertySearcher,
          property
        })
      );
    }

    public openSelfDisclosureModal(
      propertyMatch: PropertyMatchBean,
      intent?: boolean
    ) {
      this.store.dispatch(
        new fromAppState.LoadSelfDisclosureData(propertyMatch)
      );
      this.store.dispatch(new fromAppState.OpenSelfDisclosuresModal(intent));
    }

    public canChooseAnotherAppointment(propertyMatch: PropertyMatchBean) {
      return (
        (propertyMatch?.status === ApplicantStatus.DECLARE_INTENT &&
          propertyMatch?.appointmentSlotsAvailable) ||
        this.hasExclusiveAppointments(propertyMatch, this.appointmentsData)
      );
    }

    public hasAppointmentInTheFuture(upcomingAppointmentDate: Date) {
      return moment(upcomingAppointmentDate).isAfter(moment.now());
    }

    public hasCustomQuestion(propertyMatch: PropertyMatchBean) {
      return propertyMatch?.hasQuestions;
    }

    public showExposeDownload(propertyMatch: PropertyMatchBean) {
      return (
        propertyMatch?.property.downloadExposeAllowed &&
        (propertyMatch?.status === ApplicantStatus.DECLARE_INTENT ||
          propertyMatch?.status === ApplicantStatus.INTENT ||
          propertyMatch?.status === ApplicantStatus.INVITED_TO_VIEWING ||
          propertyMatch?.status === ApplicantStatus.ATTENDING_TO_VIEWING ||
          propertyMatch?.status === ApplicantStatus.TENANT)
      );
    }

    public hasExclusiveAppointments(
      propertyMatch: PropertyMatchBean,
      appointmentBundles: AppointmentsBundles[] = []
    ) {
      return appointmentBundles
        .filter(
          appointmentsBundle =>
            appointmentsBundle.applicationId === propertyMatch?.id
        )
        .map(appointmentsBundle => {
          const invitedAppointmentIds =
            appointmentsBundle.appointmentInvitations.map(
              invites => invites.appointmentId
            );
          const invitedAppointmentList = appointmentsBundle.appointments.filter(
            appointment => invitedAppointmentIds?.includes(appointment.id)
          );
          return (
            invitedAppointmentList.filter(
              appointment => appointment.exclusiveAttendees.length > 0
            )?.length > 0
          );
        })
        .reduce((a: boolean, b: boolean) => a || b, false);
    }
  };
}
