import {
  AfterContentChecked,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  APPLICATION_STATUS_CONFIGS,
  ClipboardService,
  ComponentsModule,
  DateTimePipe,
  DEFAULT_APPLICATION_STATUS,
  DownloadService,
  EnergyClassPipe,
  FloorPipe,
  getCurrentLocale,
  Go,
  HumanizeBooleanPipe,
  I18nService,
  isValueNotNullAndUndefined,
  LocalStorageService,
  ModalService,
  SelectConversation,
  SessionStorageService,
  TenantApplicationStatusConfig
} from '@ui/legacy-lib';

import {
  ApplicantStatus,
  Attachment,
  AvailableLanguageCodesEnum,
  Constructor,
  impressum_agb_links_config,
  PropertyApplicationStatus,
  PropertyAttachmentsTypes,
  PropertyBean,
  PropertyMatchBean,
  SalesLegalTextType
} from '@ui/shared/models';

import { filter, switchMap } from 'rxjs/operators';

import * as fromAppState from 'tenant-pool/+state';
import { getPropertyMatchDataActionState } from 'tenant-pool/+state';
import * as fromActions from 'tenant-pool/+state/property-matches/property-matches.actions';
import { MainPageNavigation, storageKeys } from 'tenant-pool/config';
import { mixinPropertiesActions } from 'tenant-pool/screens/objects/components/properties/mixin';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { CurrencyPipe, NgClass, TitleCasePipe } from '@angular/common';
import { ApplicationActionsComponent } from '../application-actions/application-actions.component';
import { PropertyCardTenantComponent } from '../property-card-tenant/property-card-tenant.component';
import { CostDetailsCellComponent } from './components/cost-details-cell/cost-details-cell.component';

class PropertyExposeBase {}

const _PropertyExposeComponentMixinBase =
  mixinPropertiesActions<Constructor>(PropertyExposeBase);

@UntilDestroy()
@Component({
  selector: 'app-property-expose',
  templateUrl: './property-expose.component.html',
  styleUrls: ['./property-expose.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ComponentsModule,
    PropertyCardTenantComponent,
    CostDetailsCellComponent,
    SvgIconComponent,
    ApplicationActionsComponent,
    TranslateModule,
    TitleCasePipe,
    CurrencyPipe,
    DateTimePipe,
    HumanizeBooleanPipe,
    FloorPipe,
    EnergyClassPipe
  ]
})
export class PropertyExposeComponent
  extends _PropertyExposeComponentMixinBase
  implements OnInit, AfterContentChecked
{
  private store = inject(Store);
  private localStorageService = inject(LocalStorageService);
  private sessionStorage = inject(SessionStorageService);
  private route = inject(ActivatedRoute);
  private downloadService = inject(DownloadService);
  private modalService = inject(ModalService);
  private clipboardService = inject(ClipboardService);
  private router = inject(Router);
  private i18nService = inject(I18nService);

  public questionsLoaded: boolean;
  public propertyMatch: PropertyMatchBean;
  public applicationStates = PropertyApplicationStatus;
  public floorPlan: Attachment;
  public floorPlanList: Attachment[];
  public descriptionExpanded = false;
  public isProcessing: boolean;
  public appointmentSlotsAvailable: boolean;
  public applicationStatusConfig: TenantApplicationStatusConfig;
  public showInteriorDetailsList = true;
  public showEnergyDetailsList = true;
  public salesLegalTextCheckboxesValid = false;
  public links = impressum_agb_links_config.links;
  public currentLanguageCode: AvailableLanguageCodesEnum;

  private origin: string;

  id = input.required<string>();
  @Input() property: PropertyBean;
  @Output() landingApply = new EventEmitter();
  @Output() applyAnonymous = new EventEmitter();
  @Output() openSalesModal = new EventEmitter();

  @ViewChild('interiorDetailsList') interiorDetailsList: ElementRef;
  @ViewChild('energyDetailsList') energyDetailsList: ElementRef;

  @HostBinding('class') get class() {
    return this.status === PropertyApplicationStatus.ATTENDING_TO_VIEWING ||
      this.status === PropertyApplicationStatus.ALL_APPOINTMENTS_DECLINED
      ? 'spaced'
      : null;
  }

  public get status() {
    return this.propertyMatch?.status;
  }

  public get showAddress() {
    return (
      this.applicationStatusConfig?.forceDisplayOfAddress ||
      this.property?.data?.showAddress
    );
  }

  public get applicantStatus(): ApplicantStatus {
    return (
      (this.propertyMatch?.status as ApplicantStatus) ||
      DEFAULT_APPLICATION_STATUS
    );
  }

  public get hasAnyDescription() {
    return (
      this.getObjectDescription ||
      this.getFurnishingDescription ||
      this.getObjectLocationText ||
      this.getObjectMiscellaneousText
    );
  }

  public get getObjectDescription(): string {
    return this.i18nService.getMultiLanguageStringValue(
      this.property?.data?.objectDescription,
      this.currentLanguageCode
    );
  }

  public get getFurnishingDescription(): string {
    return this.i18nService.getMultiLanguageStringValue(
      this.property?.data?.furnishingDescription,
      this.currentLanguageCode
    );
  }

  public get getObjectLocationText(): string {
    return this.i18nService.getMultiLanguageStringValue(
      this.property?.data?.objectLocationText,
      this.currentLanguageCode
    );
  }

  public get getObjectMiscellaneousText(): string {
    return this.i18nService.getMultiLanguageStringValue(
      this.property?.data?.objectMiscellaneousText,
      this.currentLanguageCode
    );
  }

  public get hasDocuments() {
    return this.property?.data?.documents?.length;
  }

  public get getEnergyConsumption() {
    const type = this.getEnergyCertificateType(this.property);
    if (type === 'USAGE_IDENTIFICATION') {
      return this.property?.data?.energyCertificate?.usageCertificate
        ?.energyConsumption;
    } else if (type === 'DEMAND_IDENTIFICATION') {
      return this.property?.data?.energyCertificate?.demandCertificate
        ?.endEnergyConsumption;
    }
    return null;
  }

  public get getEnergyEfficiencyClass() {
    const type = this.getEnergyCertificateType(this.property);
    if (type === 'USAGE_IDENTIFICATION') {
      return this.property?.data?.energyCertificate?.usageCertificate
        ?.energyEfficiencyClass;
    } else if (type === 'DEMAND_IDENTIFICATION') {
      return this.property?.data?.energyCertificate?.demandCertificate
        ?.energyEfficiencyClass;
    }
    return null;
  }

  public get showActionBar() {
    return (
      !['TENANT', 'REJECTED'].includes(this.status) &&
      !this.propertyMatch?.applicationBlocked
    );
  }

  public get shortUrlToken() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.route?.snapshot?.queryParams?.token;
  }

  public get isApplyPage() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.route?.snapshot?.data?.isApplyPage;
  }

  public get hasSalesCheckboxes(): boolean {
    return this.property?.salesLegalTexts?.some(
      s => s.type === SalesLegalTextType.CHECKBOX
    );
  }

  ngOnInit() {
    this.origin = this.route.snapshot.queryParams.origin;

    this.store
      .select(getCurrentLocale)
      .pipe(untilDestroyed(this))
      .subscribe(languageCode => (this.currentLanguageCode = languageCode));

    this.store
      .select(fromAppState.getProcessApplicationActionState)
      .pipe(untilDestroyed(this))
      .subscribe(isProcessing => (this.isProcessing = isProcessing.pending));

    this.applicationStatusConfig = APPLICATION_STATUS_CONFIGS[this.status];

    const confirmIntentForApplication = this.sessionStorage.getItem(
      storageKeys.confirmIntentForApplication
    );
    const customQuestionApplicationId = this.localStorageService.getItem(
      storageKeys.customQuestionApplicationId
    );
    if (confirmIntentForApplication) {
      this.store.dispatch(
        fromActions.LoadPropertyMatchBean({ id: confirmIntentForApplication })
      );
      this.store.dispatch(fromAppState.OpenPostLoginModal());
    } else if (customQuestionApplicationId) {
      this.store.dispatch(fromAppState.OpenPostLoginModal());
    } else if (!this.isApplyPage) {
      this.store.dispatch(fromActions.LoadPropertyMatchBean({ id: this.id() }));
    }

    this.store
      .select(getPropertyMatchDataActionState)
      .pipe(
        filter(state => !state.pending && state.done),
        switchMap(() => this.store.select(fromAppState.getExposeData)),
        filter(data => !!data),
        untilDestroyed(this)
      )
      .subscribe(data => {
        this.propertyMatch = data;
        this.property = data.property;
        this.floorPlanList = this.getFloorPlans(this.property?.data?.documents);
        this.floorPlan = this.floorPlanList?.splice(0, 1)[0];

        if (
          data.hasUnansweredQuestions &&
          !this.questionsLoaded &&
          this.propertyMatch.status !== PropertyApplicationStatus.TENANT &&
          this.origin
        ) {
          this.questionsLoaded = true;
          this.loadPropertyMatchBeanQuestions(this.propertyMatch);
        }

        const selfDisclosureDeclareIntentId =
          this.localStorageService.getItem<number>(
            storageKeys.selfDisclosureDeclareIntentId
          );

        if (
          selfDisclosureDeclareIntentId &&
          this.propertyMatch.property?.selfDisclosureId ===
            selfDisclosureDeclareIntentId.toString()
        ) {
          this.openSelfDisclosureModal(this.propertyMatch, true);
        }
      });
  }

  ngAfterContentChecked() {
    // TODO:
    // generate list by data iteration instead of composing list manually and check for rendered children afterwards
    this.showInteriorDetailsList = this.hasChildNodes(this.interiorDetailsList);
    this.showEnergyDetailsList = this.hasChildNodes(this.energyDetailsList);
  }

  public isNotNullAndUndefined(value: any) {
    return isValueNotNullAndUndefined(value);
  }

  public back() {
    if (this.route?.snapshot?.queryParams?.activeConversation) {
      this.store.dispatch(
        new Go({
          path: ['messenger'],
          query: {
            activeConversation:
              this.route?.snapshot?.queryParams?.activeConversation
          }
        })
      );
      this.store.dispatch(
        new SelectConversation(
          this.route?.snapshot?.queryParams?.activeConversation
        )
      );
    } else {
      this.store.dispatch(
        new Go({
          path: [
            this.origin
              ? this.origin
              : this.propertyMatch?.status === PropertyApplicationStatus.TENANT
                ? MainPageNavigation.NEW_HOME
                : `${MainPageNavigation.PROPERTIES}/${MainPageNavigation.APPLICATIONS}`
          ]
        })
      );
    }
  }

  public getOtherDocuments(attachments: Attachment[]) {
    const docs = attachments.filter(
      att => att.type !== PropertyAttachmentsTypes.FLOOR_PLAN
    );
    return [...docs, ...(this.floorPlanList || [])];
  }

  public showMore() {
    if (!this.descriptionExpanded) this.descriptionExpanded = true;
  }

  public onLandingApply() {
    this.landingApply.emit();
  }

  public onApplyAnonymous(applyAnonymous) {
    this.applyAnonymous.emit(applyAnonymous);
  }

  public onOpenSalesModal() {
    this.openSalesModal.emit();
  }

  public updateSalesLegalTextCheckboxesValid(valid: boolean): void {
    this.salesLegalTextCheckboxesValid = valid;
  }

  private getEnergyCertificateType(property: PropertyBean) {
    return property?.data?.energyCertificate?.energyCertificateType;
  }

  private getFloorPlans(attachments: Attachment[]) {
    return attachments?.filter(
      att => att?.type === PropertyAttachmentsTypes.FLOOR_PLAN
    );
  }

  private hasChildNodes(contentProjection: ElementRef) {
    const childNodes: NodeList = (
      contentProjection?.nativeElement as HTMLElement
    )?.childNodes;
    if (childNodes?.length) {
      // return has childNodes excluding comments (nodeType: 8)
      return !!Array.from(childNodes).filter(node => node.nodeType !== 8)
        ?.length;
    } else {
      return false;
    }
  }
}
