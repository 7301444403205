import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject
} from '@angular/core';
import { ThemeService } from '@ui/legacy-lib';
import { UrlUtilService } from 'tenant-pool/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-info-legacy',
  templateUrl: './customer-info-legacy.component.html',
  styleUrls: ['./customer-info-legacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule]
})
export class CustomerInfoLegacyComponent {
  private themeService = inject(ThemeService);
  private urlHelperService = inject(UrlUtilService);

  @Input() name: string;
  @Input() logo: string;
  @Input() logoRedirectUrl: string;
  @Input() nameHeading: string;
  @Input() showOfferFrom = true;
  @Input() showInCooperationWith = true;

  public get showInCooperationWithImmomio() {
    return this.logo;
  }

  public get customerLogo() {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  public get getLogoRedirectUrl() {
    return this.urlHelperService.getLogoRedirectUrl(this.logoRedirectUrl);
  }
}
