import { Component, OnInit, inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { ModalService } from '@ui/legacy-lib';

import * as fromAppState from 'tenant-pool/+state';
import { NAVIGATION_LINK } from 'tenant-pool/config';
import {
  PaymentPosition,
  PaymentDirectDebitPayInInput,
  PaymentType,
  PaymentExecutionInput,
  ManualPaymentInput,
  PaymentStatus,
  PaymentPayPalPayInInput
} from '@ui/shared/models';
import { ibanValidator } from 'ngx-iban';
import { zipCodeValidator } from '@ui/legacy-lib';
import { oneTrueBooleanInFormArrayValidator } from '@ui/legacy-lib';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, filter } from 'rxjs';
import { DOCUMENT, NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { SvgService } from '@ui/legacy-lib';
import { SafeHtml } from '@angular/platform-browser';
import { ThemePropertyKeys } from '@ui/legacy-lib';
import { PaymentsService } from 'tenant-pool/core/services/payments.service';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';
import { Go } from '@ui/legacy-lib';
import { PaymentPositionsRequestFeedbackModalComponent } from '../payment-positions-request-feedback-modal/payment-positions-request-feedback-modal.component';
import { PaymentPositionsRequestAlreadyPaidModalComponent } from '../payment-positions-request-already-paid-modal/payment-positions-request-already-paid-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-payment-positions-request',
  templateUrl: './payment-positions-request.component.html',
  styleUrls: ['./payment-positions-request.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
    NgTemplateOutlet,
    TranslateModule,
    CurrencyPipe
  ]
})
export class PaymentPositionsRequestComponent implements OnInit {
  private modalService = inject(ModalService);
  private formBuilder = inject(FormBuilder);
  private store = inject(Store);
  private route = inject(ActivatedRoute);
  private paymentsService = inject(PaymentsService);
  private svgService = inject(SvgService);
  private document = inject(DOCUMENT);

  public form: FormGroup;
  public positionsFormArray: FormArray;
  public addressForm: FormGroup;
  public svgXML: SafeHtml;

  public token: string;
  public positions: PaymentPosition[];
  public customerName: string;
  public priceSum = 0;
  public showBankDetails = false;
  public showSEPA = false;
  public showPayPal = false;

  //@TO DO need to be internationalized
  protected currency = 'EUR';

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      fullName: ['', Validators.required],
      iban: ['', Validators.compose([Validators.required, ibanValidator()])],
      address: this.formBuilder.group({
        street: ['', Validators.required],
        houseNumber: ['', Validators.required],
        zipCode: ['', [Validators.required, zipCodeValidator]],
        city: ['', Validators.required]
      }),
      positionIds: this.formBuilder.array(
        [],
        oneTrueBooleanInFormArrayValidator()
      ),
      type: [PaymentType.SEPA, Validators.required]
    });

    this.positionsFormArray = this.form.get('positionIds') as FormArray;
    this.addressForm = this.form.get('address') as FormGroup;

    this.token = this.route.snapshot.queryParams.token;

    if (this.token) {
      this.subscribeToFormValueChanges();
      this.subscribeToFetchPaymentRequest();

      this.svgService
        .loadSvgAsXML('/assets/images/Fallback_New_Home.svg')
        .pipe(untilDestroyed(this))
        .subscribe(svg => {
          let replaceSvg = this.svgService.replaceFillColorWithVar(
            svg,
            '#F5F8FF',
            ThemePropertyKeys.THEME_COLOR_CARD_BACKGROUND
          );

          replaceSvg = this.svgService.replaceFillColorWithVar(
            replaceSvg,
            '#EBF2FF',
            ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER
          );

          replaceSvg = this.svgService.removeFirstWidthHeight(replaceSvg);

          this.svgXML = this.svgService.sanitizeSvgHtml(replaceSvg);
        });

      this.paymentsService.applyBranding(this.token);
    } else {
      this.redirectToResult();
    }
  }

  public onAlreadyPaid() {
    this.modalService
      .open<PaymentPositionsRequestAlreadyPaidModalComponent>(
        PaymentPositionsRequestAlreadyPaidModalComponent,
        {
          data: {
            selectedPositions: this.getSelectedPositions(),
            currency: this.currency
          },
          windowClass: 'd-flex align-items-center'
        }
      )
      .onClose()
      .subscribe(() => this.processPayment(false, true));
  }

  public onSend() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (this.form.value.positionIds.includes(false)) {
      this.modalService
        .open<PaymentPositionsRequestFeedbackModalComponent>(
          PaymentPositionsRequestFeedbackModalComponent,
          { windowClass: 'd-flex align-items-center' }
        )
        .onClose()
        .subscribe((alreadyPaid: boolean) =>
          this.processPayment(alreadyPaid, false)
        );
    } else {
      this.processPayment(false, false);
    }
  }

  private subscribeToFetchPaymentRequest() {
    this.store
      .select(fromAppState.getPaymentRequest)
      .pipe(
        filter(request => !!request),
        untilDestroyed(this)
      )
      .subscribe(request => {
        if (request.positions) {
          this.customerName = request.customerName;
          this.positions = request.positions.filter(
            position =>
              position.status === PaymentStatus.OPEN ||
              position.status === PaymentStatus.OVERDUE ||
              position.status === PaymentStatus.ERROR
          );

          this.positionsFormArray.clear();

          this.positions.forEach(() =>
            this.positionsFormArray.push(this.formBuilder.control(true))
          );

          this.priceSum = this.calculatePriceSum(this.positionsFormArray.value);

          if (this.positions.length === 0) {
            this.redirectToResult(this.token);
          }

          this.showSEPA = request.allowedPaymentMethods.includes(
            PaymentType.SEPA
          );
          this.showPayPal = request.allowedPaymentMethods.includes(
            PaymentType.PAYPAL
          );
          this.form
            .get('type')
            .setValue(this.showSEPA ? PaymentType.SEPA : PaymentType.PAYPAL);
        } else {
          this.redirectToResult(this.token);
        }
      });

    this.store.dispatch(
      fromAppState.fetchPaymentRequest({ token: this.token })
    );
  }

  private subscribeToFormValueChanges() {
    this.positionsFormArray.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(positionsArray => {
        this.priceSum = this.calculatePriceSum(positionsArray);
      });

    this.form
      .get('type')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(type => {
        const isSepa = type === PaymentType.SEPA;
        this.showBankDetails = isSepa;
        if (isSepa) {
          this.form.get('fullName').enable();
          this.form.get('iban').enable();
          this.form.get('address').enable();
        } else {
          this.form.get('fullName').disable();
          this.form.get('iban').disable();
          this.form.get('address').disable();
        }
      });
  }

  private calculatePriceSum(positionsArray: number[]): number {
    return this.positions.reduce((sum, position, index) => {
      return positionsArray[index] ? sum + position.amount : sum;
    }, 0);
  }

  private getPaymentExecutionPayload(): PaymentExecutionInput {
    const { positionIds, type, ...rest } = this.form.value;
    const positionsToPay: number[] = [];
    let paymentDirectDebitPayInInput: PaymentDirectDebitPayInInput;
    let paymentPayPalPayInInput: PaymentPayPalPayInInput;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    positionIds.forEach((check: boolean, index: number) => {
      if (check) {
        positionsToPay.push(this.positions[index].id);
      }
    });

    if (type === PaymentType.SEPA) {
      paymentDirectDebitPayInInput = {
        ...rest,
        type,
        positionIds: positionsToPay
      } as PaymentDirectDebitPayInInput;
    }

    if (type === PaymentType.PAYPAL) {
      paymentPayPalPayInInput = {
        type,
        positionIds: positionsToPay
      };
    }

    return {
      token: this.token,
      payInData: paymentDirectDebitPayInInput ?? paymentPayPalPayInInput
    } as PaymentExecutionInput;
  }

  private getManualPaymentPayload(
    checkedAlreadyPaid = false
  ): ManualPaymentInput {
    const { positionIds } = this.form.value;
    const alreadyPaidPositions: number[] = [];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    positionIds.forEach((check: boolean, index: number) => {
      if ((!checkedAlreadyPaid && !check) || (checkedAlreadyPaid && check)) {
        alreadyPaidPositions.push(this.positions[index].id);
      }
    });

    return {
      token: this.token,
      positionIds: alreadyPaidPositions
    } as ManualPaymentInput;
  }

  private processPayment(
    uncheckedAlreadyPaid: boolean,
    checkedAlreadyPaid: boolean
  ) {
    const manualPaymentPayload =
      uncheckedAlreadyPaid || checkedAlreadyPaid
        ? this.getManualPaymentPayload(checkedAlreadyPaid)
        : null;
    const paymentExecutionPayload = !checkedAlreadyPaid
      ? this.getPaymentExecutionPayload()
      : null;

    if (manualPaymentPayload) {
      this.store.dispatch(
        fromAppState.manualPayment({ input: manualPaymentPayload })
      );
    }

    if (paymentExecutionPayload) {
      this.store.dispatch(
        fromAppState.paymentExecution({ input: paymentExecutionPayload })
      );
    }

    combineLatest([
      this.store.select(fromAppState.getManualPaymentActionState),
      this.store.select(fromAppState.getPaymentExecutionActionState),
      this.store.select(fromAppState.getPaymentPayInResponse)
    ])
      .pipe(
        filter(
          ([manualActionState, executionActionState, paymentPayInResponse]) =>
            !manualActionState.pending &&
            !executionActionState.pending &&
            (!executionActionState.done || paymentPayInResponse !== null)
        ),
        untilDestroyed(this)
      )
      .subscribe(([_, executionActionState, paymentPayInResponse]) => {
        if (executionActionState.done && paymentPayInResponse.redirectUrl) {
          this.document.location.href = paymentPayInResponse.redirectUrl;
        } else {
          this.redirectToResult(this.token);
        }
      });
  }

  private getSelectedPositions(): PaymentPosition[] {
    // eslint-disable-next-line
    return this.form.value.positionIds.reduce(
      (previous: PaymentPosition[], current: boolean, index: number) => {
        if (current) {
          return [...previous, this.positions[index]];
        }
        return previous;
      },
      []
    );
  }

  private redirectToResult(token: string = null) {
    this.store.dispatch(
      new Go({
        path: [NAVIGATION_LINK.PAYMENT_POSITIONS_RESULT],
        ...(token && { query: { token } })
      })
    );
  }
}
