import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject
} from '@angular/core';

import { DownloadService } from '@ui/legacy-lib';
import { BaseStep } from '@ui/legacy-lib';
import { PropertySearcherUser, SearchProfile } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { ProfileSummaryComponent } from '../profile-summary/profile-summary.component';

@Component({
  selector: 'app-profile-review',
  templateUrl: './profile-review.component.html',
  styleUrls: ['./profile-review.component.scss'],
  standalone: true,
  imports: [ProfileSummaryComponent, TranslateModule]
})
export class ProfileReviewComponent extends BaseStep implements OnInit {
  private downloadService = inject(DownloadService);

  @Input() userData: PropertySearcherUser;
  @Input() guestMode = false;
  @Input() searchProfiles: SearchProfile[];
  @Input() schufaCard: boolean;
  @Output() editSearchProfile = new EventEmitter<SearchProfile>();
  @Output() deleteSearchProfile = new EventEmitter<string>();
  @Output() addSearchProfile = new EventEmitter();

  public onEditSearchProfile(searchProfile: SearchProfile) {
    this.editSearchProfile.emit(searchProfile);
  }

  public onDeleteSearchProfile(id: string) {
    this.deleteSearchProfile.emit(id);
  }

  public onAddSearchProfile() {
    this.addSearchProfile.emit();
  }
}
