import { Directive, inject, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as tooltipState from 'tenant-pool/+state/tooltip';
import { BaseState } from '@ui/legacy-lib';
import { TooltipDirective } from '@ui/legacy-lib';

@UntilDestroy()
@Directive({
  selector: '[appTenantTooltip]',
  standalone: true
})
export class TenantTooltipDirective extends TooltipDirective implements OnInit {
  private store = inject<Store<BaseState>>(Store);

  @Input() appTooltipAnchor: string;
  @Input() appTooltipUseMobileStrategy = false;

  ngOnInit() {
    super.ngOnInit();

    this.store
      .select(tooltipState.getTooltipProgress)
      .pipe(untilDestroyed(this))
      .subscribe(
        progress => (this.componentRef.instance.tooltipProgress = progress)
      );

    this.componentRef.instance.continueEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltip => {
        this.store.dispatch(
          tooltipState.setTooltipsSeen({ ids: [tooltip.id] })
        );
        this.store.dispatch(tooltipState.continueTooltipProgress());
      });

    this.componentRef.instance.closeEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltipTour => {
        this.store.dispatch(tooltipState.setTooltipTourSeen({ tooltipTour }));
        this.store.dispatch(tooltipState.resetTooltipProgress());
      });
  }
}
